import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from '../views/layouts/AuthLayout'
import AppLayout from '../views/layouts/AppLayout'

import * as auth from '../services/auth_service'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: AuthLayout,
        children: [
            {
                path: '',
                name: 'landing',
                component: () => import(/* webpackChunkName: "landing" */ '../views/landings/Landing.vue'),
            },
            {
                path: 'health-test',
                name: 'HealthTest',
                component: () => import(/* webpackChunkName: "HealthTest" */ '../views/landings/Clients/HealthTest/HealthTest.vue'),
            },
            {
                path: '/fin/h-test',
                name: 'HealthTestFin',
                component: () => import(/* webpackChunkName: "HealthTestFin" */ '../views/landings/Clients/HealthTest/HealthTestFin.vue'),
            },            
            {
                path: 'health-test-old',
                name: 'HealthTestOld',
                component: () => import(/* webpackChunkName: "HealthTestOld" */ '../views/landings/Clients/HealthTest/HealthTestOld.vue')
            },
            {
                path: 'health-req',
                name: 'HealthReq',
                component: () => import(/* webpackChunkName: "HealthReq" */ '../views/landings/Clients/HealthTest/HealthReq.vue'),
            },
            {
                path: 'health-plan',
                name: 'HealthPlan',
                component: () => import(/* webpackChunkName: "HealthPlan" */ '../views/landings/Clients/HealthPlan/HealthPlan.vue'),
            },
            {
                path: 'health-plan/step-one',
                name: 'HealthStepOne',
                component: () => import(/* webpackChunkName: "HealthStepOne" */ '../views/landings/Clients/HealthPlan/components/HealthStepOne.vue'),
            },
            {
                path: 'health-plan/step-two',
                name: 'HealthStepTwo',
                component: () => import(/* webpackChunkName: "HealthStepTwo" */ '../views/landings/Clients/HealthPlan/components/HealthStepTwo.vue'),
            },
            {
                path: 'health-plan/step-three',
                name: 'HealthStepThree',
                component: () => import(/* webpackChunkName: "HealthStepThree" */ '../views/landings/Clients/HealthPlan/components/HealthStepThree.vue'),
            },
            {
                path: 'vizitka',
                name: 'Vizitka',
                component: () => import(/* webpackChunkName: "Vizitka" */ '../views/landings/Clients/Vizitka.vue')
            },
            {
                path: 'beauty-elixir',
                name: 'BeautyElixir',
                component: () => import(/* webpackChunkName: "BeautyElixir" */ '../views/landings/Clients/BeautyElixir/BeautyElixir.vue')
            },
            {
              path: 'man-elixir',
              name: 'ManElixir',
              component: () => import(/* webpackChunkName: "ManElixir" */ '../views/landings/Clients/ManElixir/ManElixir.vue')
            },
            {
              path: 'robo-money',
              name: 'RoboMoney',
              component: () => import(/* webpackChunkName: "RoboMoney" */ '../views/landings/Clients/RoboMoney/RoboMoney.vue')
            },
            {
                path: 'robo-money-short',
                name: 'RoboMoneyShort',
                component: () => import(/* webpackChunkName: "RoboMoneyShort" */ '../views/landings/Clients/RoboMoney/RoboMoneyShort.vue')
              },
            {
              path: 'robo-money-mlm',
              name: 'RoboMoneyMlm',
              component: () => import(/* webpackChunkName: "RoboMoneyMlm" */ '../views/landings/Clients/RoboMoney/RoboMoneyMlm.vue')
            },
            {
                path: 'gifts',
                name: 'Gifts',
                component: () => import(/* webpackChunkName: "Gifts" */ '../views/landings/Clients/Gifts/Gifts.vue')
            },
            {
                path: 'cell',
                name: 'Cell',
                component: () => import(/* webpackChunkName: "Cell" */ '../views/landings/Clients/Cell/Cell.vue')
            },
            {
                path: 'cell-ru',
                name: 'CellRU',
                component: () => import(/* webpackChunkName: "CellRU" */ '../views/landings/Clients/Cell/CellRU.vue')
            },
            {
                path: 'zeitgard',
                name: 'ZeitGard',
                component: () => import(/* webpackChunkName: "ZeitGard" */ '../views/landings/Clients/ZeitGard/ZeitGard.vue')
            },
            {
              path: 'soul-of-nature',
              name: 'SoulOfNature',
              component: () => import(/* webpackChunkName: "SoulOfNature" */ '../views/landings/Clients/Aroma/SoulOfNature.vue')
            },
            {
              path: 'aloe-vera',
              name: 'AloeVera',
              component: () => import(/* webpackChunkName: "AloeVera" */ '../views/landings/Clients/Aloe/AloeVera.vue')
            },
            {
              path: 'av-box',
              name: 'AvBox',
              component: () => import(/* webpackChunkName: "AvBox" */ '../views/landings/Clients/AvBox/AvBox.vue')
            },
            {
                path: 'biz-test',
                name: 'BizTest',
                component: () => import(/* webpackChunkName: "BizTest" */ '../views/landings/Clients/BizTest/BizTest.vue')
            },
            {
                path: 'biz',
                name: 'Biz',
                component: () => import(/* webpackChunkName: "Biz" */ '../views/landings/Clients/Biz/Biz.vue')
            },
            {
                path: 'biz-ua',
                name: 'BizUA',
                component: () => import(/* webpackChunkName: "BizUA" */ '../views/landings/Clients/Biz/BizUA.vue')
            },
            {
                path: 'biz-kz',
                name: 'BizKZ',
                component: () => import(/* webpackChunkName: "BizKZ" */ '../views/landings/Clients/Biz/BizKZ.vue')
            },
            {
                path: 'leedbot',
                name: 'Leedbot',
                component: () => import(/* webpackChunkName: "Leedbot" */ '../views/landings/Clients/Leedbot/Leedbot.vue')
            },
            {
                path: 'leedbot-ua',
                name: 'LeedbotUA',
                component: () => import(/* webpackChunkName: "LeedbotUA" */ '../views/landings/Clients/Leedbot/LeedbotUA.vue')
            },
            {
                path: 'leedbot-kz',
                name: 'LeedbotKZ',
                component: () => import(/* webpackChunkName: "LeedbotKZ" */ '../views/landings/Clients/Leedbot/LeedbotKZ.vue')
            },
            {
                path: 'leedbot-kz-r',
                name: 'LeedbotKZR',
                component: () => import(/* webpackChunkName: "LeedbotKZR" */ '../views/landings/Clients/Leedbot/LeedbotKZR.vue')
            },
            {
                path: 'leed-book',
                name: 'LeedBook',
                component: () => import(/* webpackChunkName: "LeedBook" */ '../views/landings/Clients/Leedbot/LeedBook.vue')
            },
            {
                path: 'privacy/cookies',
                name: 'Cookies',
                component: () => import(/* webpackChunkName: "Cookies" */ '../views/landings/privacy/Cookies.vue')
            },
            {
                path: 'privacy/policy',
                name: 'Policy',
                component: () => import(/* webpackChunkName: "Policy" */ '../views/landings/privacy/Policy.vue')
            },
            {
                path: 'privacy/personal',
                name: 'Personal',
                component: () => import(/* webpackChunkName: "Personal" */ '../views/landings/privacy/Personal.vue')
            },
        ]
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
            },
            {
                path: 'register',
                name: 'register',
                component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
            },
            {
                path: 'forgot-password',
                name: 'forgotPassword',
                component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPassword.vue'),
            },
            {
                path: 'forgot-password-success',
                name: 'forgotPasswordSuccess',
                component: () => import(/* webpackChunkName: "forgotPasswordSuccess" */ '../views/auth/ForgotPasswordSuccess.vue'),
            },
            {
                path: 'reset-password',
                name: 'resetPassword',
                component: () => import(/* webpackChunkName: "resetPassword" */ '../views/auth/ResetPassword.vue'),
            },
            {
                path: 'verify-email',
                name: 'verifyEmail',
                component: () => import(/* webpackChunkName: "verifyEmail" */ '../views/auth/VerifyEmail.vue'),
            },
            {
                path: 'verify-email-success',
                name: 'verifyEmailSuccess',
                component: () => import(/* webpackChunkName: "verifyEmailSuccess" */ '../views/auth/VerifyEmailSuccess.vue'),
            }
        ],
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next()
            } else {
                next('/dashboard')
            }
        }
    },
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '../views/pages/Dashboard/Dashboard')
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "Profile" */ '../views/pages/Profile')
            },
            {
                path: 'prospect/:id',
                name: 'prospectPage',
                component: () => import(/* webpackChunkName: "prospectPage" */ '../views/pages/ProspectPage')
            },
            {
                path: 'prospects',
                name: 'myProspects',
                component: () => import(/* webpackChunkName: "myProspects" */ '../views/pages/MyProspects')
            },
            {
                path: 'prospects-basket',
                name: 'myProspectsBasket',
                component: () => import(/* webpackChunkName: "myProspectsBasket" */ '../views/pages/MyProspectsBasket')
            },
            {
                path: 'instruments',
                name: 'Instruments',
                component: () => import(/* webpackChunkName: "Instruments" */ '../views/pages/Instruments/Instruments')
            },
            {
                path: 'instruments-pay',
                name: 'PayInstruments',
                component: () => import(/* webpackChunkName: "payInstruments" */ '../views/pages/Instruments/PayInstruments')
            },
            {
                path: 'payments-page',
                name: 'PaymentsPage',
                component: () => import(/* webpackChunkName: "PaymentsPage" */ '../views/pages/Instruments/PaymentsPage')
            },
            {
                path: 'support',
                name: 'Support',
                component: () => import(/* webpackChunkName: "Support" */ '../views/pages/Support/Support')
            },
            {
                path: 'instruments/zg-instrum',
                name: 'ZgInstrum',
                component: () => import(/* webpackChunkName: "ZgInstrum" */ '../views/pages/Instruments/ZgInstrum')
            },
            {
              path: 'instruments/aroma-instrum',
              name: 'AromaInstrum',
              component: () => import(/* webpackChunkName: "AromaInstrum" */ '../views/pages/Instruments/AromaInstrum')
            },
            {
              path: 'instruments/aloe-instrum',
              name: 'AloeInstrum',
              component: () => import(/* webpackChunkName: "AloeInstrum" */ '../views/pages/Instruments/AloeInstrum')
            },
            {
              path: 'instruments/av-box-instrum',
              name: 'AvBoxInstrum',
              component: () => import(/* webpackChunkName: "AvBoxInstrum" */ '../views/pages/Instruments/AvBoxInstrum')
            },
            {
                path: 'instruments/test-instrum',
                name: 'TestInstrum',
                component: () => import(/* webpackChunkName: "TestInstrum" */ '../views/pages/Instruments/TestInstrum')
            },
            {
                path: 'instruments/vizitka-instrum',
                name: 'VizitkaInstrum',
                component: () => import(/* webpackChunkName: "VizitkaInstrum" */ '../views/pages/Instruments/VizitkaInstrum')
            },
            {
                path: 'instruments/start-products',
                name: 'StartProducts',
                component: () => import(/* webpackChunkName: "StartProducts" */ '../views/pages/Instruments/StartProducts')
            },
            {
                path: 'instruments/be-instrum',
                name: 'BeInstrum',
                component: () => import(/* webpackChunkName: "BeInstrum" */ '../views/pages/Instruments/BeInstrum')
            },
            {
              path: 'instruments/me-instrum',
              name: 'MeInstrum',
              component: () => import(/* webpackChunkName: "MeInstrum" */ '../views/pages/Instruments/MeInstrum')
          },
            {
                path: 'instruments/leedbot-instrum',
                name: 'LeedbotInstrum',
                component: () => import(/* webpackChunkName: "LeedbotInstrum" */ '../views/pages/Instruments/LeedbotInstrum')
            },
            {
                path: 'instruments/biz-instrum',
                name: 'BizInstrum',
                component: () => import(/* webpackChunkName: "BizInstrum" */ '../views/pages/Instruments/BizInstrum')
            },
            {
                path: 'instruments/biztest-instrum',
                name: 'BizTestInstrum',
                component: () => import(/* webpackChunkName: "BizTestInstrum" */ '../views/pages/Instruments/BizTestInstrum')
            },
            {
                path: 'instruments/gifts-instrum',
                name: 'GiftsInstrum',
                component: () => import(/* webpackChunkName: "GiftsInstrum" */ '../views/pages/Instruments/GiftsInstrum')
            },
            {
                path: 'instruments/cell-instrum',
                name: 'CellInstrum',
                component: () => import(/* webpackChunkName: "CellInstrum" */ '../views/pages/Instruments/CellInstrum')
            },
            /** learn */
            {
              path: 'instruments/req-health-learn',
              name: 'ReqHealthLearn',
              component: () => import(/* webpackChunkName: "ReqHealthLearn" */ '../views/pages/Instruments/Learn/ReqHealthLearn')
            },
            {
              path: 'instruments/robo-money',
              name: 'RoboMoneyLearn',
              component: () => import(/* webpackChunkName: "RoboMoneyLearn" */ '../views/pages/Instruments/Learn/RoboMoney')
            },
            {
              path: 'instruments/star-track',
              name: 'StarTrackLearn',
              component: () => import(/* webpackChunkName: "StarTrackLearn" */ '../views/pages/Instruments/Learn/StarTrackLearn')
            },
            {
              path: 'instruments/zapusk-learn',
              name: 'ZapuskLearn',
              component: () => import(/* webpackChunkName: "ZapuskLearn" */ '../views/pages/Instruments/Learn/ZapuskLearn')
            },
            {
              path: 'instruments/online-learn',
              name: 'OnlineLearn',
              component: () => import(/* webpackChunkName: "OnlineLearn" */ '../views/pages/Instruments/Learn/OnlineLearn')
            },
            {
              path: 'instruments/grow-team',
              name: 'GrowTeamLearn',
              component: () => import(/* webpackChunkName: "GrowTeamLearn" */ '../views/pages/Instruments/Learn/GrowTeamLearn')
            },
            {
              path: 'instruments/virtual-helper',
              name: 'VirtualLearn',
              component: () => import(/* webpackChunkName: "VirtualLearn" */ '../views/pages/Instruments/Learn/VirtualLearn')
            },
            {
              path: 'instruments/star-sales',
              name: 'StarSalesLearn',
              component: () => import(/* webpackChunkName: "StarSalesLearn" */ '../views/pages/Instruments/Learn/StarSalesLearn')
            },
            {
              path: 'instruments/star-health',
              name: 'StarHealthLearn',
              component: () => import(/* webpackChunkName: "StarHealthLearn" */ '../views/pages/Instruments/Learn/StarHealthLearn')
            },
        ],
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/login')
            } else {
                next()
            }
        }
    },
    {
        path: '/admin/',
        component: AppLayout,
        children: [
            {
                path: 'users',
                name: 'Users',
                component: () => import(/* webpackChunkName: "Users" */ '../views/admin/users/Users')
            },
            {
                path: 'users/add',
                name: 'addUser',
                component: () => import(/* webpackChunkName: "addUser" */ '../views/admin/users/AddUser')
            },
            {
                path: 'users/:id/edit',
                name: 'editUser',
                component: () => import(/* webpackChunkName: "editUser" */ '../views/admin/users/EditUser')
            },
            {
                path: 'users/:id',
                name: 'showUser',
                component: () => import(/* webpackChunkName: "showUser" */ '../views/admin/users/ShowUser')
            },
            {
                path: 'prospects',
                name: 'Prospects',
                component: () => import(/* webpackChunkName: "Prospects" */ '../views/admin/prospects/Prospects')
            },
            {
                path: 'prospects/add',
                name: 'addProspect',
                component: () => import(/* webpackChunkName: "addProspect" */ '../views/admin/prospects/AddProspect')
            },
            {
                path: 'prospects/:id/edit',
                name: 'editProspect',
                component: () => import(/* webpackChunkName: "editProspect" */ '../views/admin/prospects/EditProspect')
            },
            {
                path: 'prospects/:id',
                name: 'showProspect',
                component: () => import(/* webpackChunkName: "showProspects" */ '../views/admin/prospects/ShowProspect')
            },
            {
              path: 'notification',
              name: 'Notification',
              component: () => import(/* webpackChunkName: "Notification" */ '../views/admin/notification/Notification')
            },
        ],
        beforeEnter(to, from, next) {
            if (!auth.isLoggedIn()) {
                next('/login')
            } else if (auth.getUserRole() === 'admin') {
                next()
            } else {
                next('/Page404')
            }
        }
    },
    {
        path: '*',
        name: 'Page404',
        component: () => import('../views/pages/Page404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
