import {http, httpFile} from "./http_service"

export function loadUsers() {
    return http().get('/admin/users')
}

export function addUser(data) {
    return http().post('/admin/users', data)
}

export function loadOneUser(id) {
    return http().get(`/admin/users/${id}`)
}

export function loadUserPackage(id) {
    return http().get(`/admin/user-package/${id}`)
}

export function loadUserAll(id) {
    return http().get(`/admin/user-all/${id}`)
}

export function loadUserTest(id) {
    return http().get(`/admin/user-test/${id}`)
}

export function loadUserBe(id) {
    return http().get(`/admin/user-be/${id}`)
}

export function loadUserGifts(id) {
    return http().get(`/admin/user-gifts/${id}`)
}

export function loadUserCell(id) {
    return http().get(`/admin/user-cell/${id}`)
}

export function loadUserLeed(id) {
    return http().get(`/admin/user-leed/${id}`)
}

export function loadUserBiz(id) {
    return http().get(`/admin/user-biz/${id}`)
}

export function loadUserBizTest(id) {
    return http().get(`/admin/user-biz-test/${id}`)
}

export function updateUser(id, data) {
    return http().put(`/admin/users/${id}`, data)
}

export function updateUserAll(id, data) {
    return http().post(`/admin/user/${id}/all`, data)
}

export function updateUserTest(id, data) {
    return http().post(`/admin/user/${id}/test`, data)
}

export function updateUserBe(id, data) {
    return http().post(`/admin/user/${id}/be`, data)
}

export function updateUserGifts(id, data) {
    return http().post(`/admin/user/${id}/gifts`, data)
}

export function updateUserCell(id, data) {
    return http().post(`/admin/user/${id}/cell`, data)
}

export function updateUserLeed(id, data) {
    return http().post(`/admin/user/${id}/leed`, data)
}

export function updateUserBiz(id, data) {
    return http().post(`/admin/user/${id}/biz`, data)
}

export function updateUserBizTest(id, data) {
    return http().post(`/admin/user/${id}/biz-test`, data)
}

export function updateAvatar(id, data) {
    return httpFile().post(`/admin/users/${id}/avatar`, data)
}

export function updateUserAuto(id, data) {
    return httpFile().post(`/admin/users/${id}/photo_auto`, data)
}

export function updateUserMoney(id, data) {
    return httpFile().post(`/admin/users/${id}/photo_money`, data)
}

export function changePassword(id, data) {
    return http().post(`/admin/users/${id}/password`, data)
}

export function deleteUser(id) {
    return http().delete(`/admin/users/${id}`)
}

export function restoreUser(id) {
    return http().post(`/admin/users/restore/${id}`)
}

export function loadAdminProspects() {
    return http().get('/admin/prospects')
}

export function addProspect(data) {
    return http().post('/admin/prospects', data)
}

export function loadOneProspect(id) {
    return http().get(`/admin/prospects/${id}`)
}

export function updateProspect(id, data) {
    return http().put(`/admin/prospects/${id}`, data)
}

export function deleteProspect(id) {
    return http().delete(`/admin/prospects/${id}`)
}

export function restoreProspect(id) {
    return http().post(`/admin/prospects/restore/${id}`)
}