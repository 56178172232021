export default {
    loggedIn(state) {
        return state.isLoggedIn
    },
    profile(state) {
        return state.profile
    },
    getUsers(state) {
        return state.users
    },
    getUser(state) {
        return state.user
    },
    getPartner(state) {
        return state.partner
    },
    getUserV(state) {
        return state.userV
    },
    getUserAll(state) {
        return state.userAll
    },
    getUserLeed(state) {
        return state.userLeed
    },
    getUserBiz(state) {
        return state.userBiz
    },
    getUserBizTest(state) {
        return state.userBizTest
    },
    getUserLeedbot(state) {
        return state.userLeedbot
    },
    getUserHealthTest(state) {
        return state.userHealthTest
    },
    getIfUserTest(state) {
        return state.ifUserTest
    },
    getUserPackages(state) {
        return state.userPackages
    },
    getUserPackage(state) {
        return state.userPackage
    },
    getProfileAll(state) {
        return state.packageAll
    },
    getProfileTest(state) {
        return state.packageTest
    },
    getProfileBe(state) {
        return state.packageBe
    },
    getProfileZg(state) {
        return state.packageZg
    },
    getProfileAroma(state) {
      return state.packageAroma
    },
    getProfileAloe(state) {
      return state.packageAloe
    },
    getProfileAvBox(state) {
      return state.packageAvBox
    },
    getProfileGifts(state) {
        return state.packageGifts
    },
    getProfileCell(state) {
        return state.packageCell
    },
    getProfileLeed(state) {
        return state.packageLeed
    },
    getProfileBiz(state) {
        return state.packageBiz
    },
    getProfileBizTest(state) {
        return state.packageBizTest
    },
    getUserTest(state) {
        return state.userTest
    },
    getUserBe(state) {
        return state.userBe
    },
    getUserGifts(state) {
        return state.userGifts
    },
    getUserCell(state) {
        return state.userCell
    },
    getProspects(state) {
        return state.prospects
    },
    getProspectsBasket(state) {
        return state.prospectsBasket
    },
    getProspect(state) {
        return state.prospect
    },
    getNewProspects(state) {
        return state.newProspects
    },
    getCountProspects(state) {
        return state.countProspects
    },
}
