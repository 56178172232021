export default {
    isLoggedIn: false,
    profile: {},
    users:[],
    user: [],
    userV: [],
    partner: [],
    userLeedbot: [],
    userHealthTest: [],
    ifUserTest: [],
    userPackages: [],
    userPackage: [],
    packageAll: [],
    packageTest: [],
    packageBe: [],
    packageZg: [],
    packageAroma: [],
    packageAloe: [],
    packageAvBox: [],
    packageGifts: [],
    packageCell: [],
    packageLeed: [],
    packageBiz: [],
    packageBizTest: [],
    userAll: [],
    userTest: [],
    userBe: [],
    userGifts: [],
    userCell: [],
    userLeed: [],
    userBiz: [],
    userBizTest: [],
    prospects: [],
    prospectsBasket: [],
    prospect: [],
    newProspects: [],
    countProspects: 0,
}
