import * as notificationService from '../../services/notification_service'

export default {
    loadNotification({commit}) {
        return new Promise((resolve, reject) => {
            notificationService.loadNotification()
                .then((response) => {
                    commit('SET_NOTIFICATION', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject (error)
                })
        })
    },
    updateNotification({commit}, notification) {
        return new Promise(() => {
            commit('UPDATE_NOTIFICATION', notification)
        })
    },
}
