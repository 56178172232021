export default {
    SET_LOGGED_IN(state, payload) {
        state.isLoggedIn = payload
    },
    SET_PROFILE(state, payload) {
        state.profile = payload
    },
    SET_USERS(state, payload) {
        state.users = payload
    },
    SET_PROSPECTS(state, payload) {
        state.prospects = payload
    },
    SET_PROSPECTS_BASKET(state, payload) {
        state.prospectsBasket = payload
    },
    SET_PROSPECTS_TODAY(state, payload) {
        state.newProspects = payload
    },
    SET_PROFILE_PACKAGES(state, payload) {
        state.userPackages = payload
    },
    SET_PROFILE_PACKAGE(state, payload) {
        state.userPackage = payload
    },
    SET_PROFILE_ALL(state, payload) {
        state.packageAll = payload
    },
    SET_PROFILE_BE(state, payload) {
        state.packageBe = payload
    },
    SET_PROFILE_ZG(state, payload) {
        state.packageZg = payload
    },
    SET_PROFILE_AROMA(state, payload) {
      state.packageAroma = payload
    },
    SET_PROFILE_ALOE(state, payload) {
      state.packageAloe = payload
    },
    SET_PROFILE_AVBOX(state, payload) {
      state.packageAvBox = payload
    },
    SET_PROFILE_GIFTS(state, payload) {
        state.packageGifts = payload
    },
    SET_PROFILE_CELL(state, payload) {
        state.packageCell = payload
    },
    SET_PROFILE_LEED(state, payload) {
        state.packageLeed = payload
    },
    SET_PROFILE_BIZ(state, payload) {
        state.packageBiz = payload
    },
    SET_PROFILE_BIZ_TEST(state, payload) {
        state.packageBizTest = payload
    },
    SET_PROFILE_TEST(state, payload) {
        state.packageTest = payload
    },
    SET_PROSPECTS_COUNT(state, payload) {
        state.countProspects = payload
    },
    SET_USER(state, payload) {
        state.user = payload
    },
    SET_USER_V(state, payload) {
        state.userV = payload
    },
    SET_PARTNER(state, payload) {
        state.partner = payload
    },
    SET_USER_ALL(state, payload) {
        state.userAll = payload
    },
    SET_USER_LEEDBOT(state, payload) {
        state.userLeedbot = payload
    },
    SET_USER_HEALTH_TEST(state, payload) {
        state.userHealthTest = payload
    },
    SET_IF_USER_TEST(state, payload) {
        state.ifUserTest = payload
    },
    SET_USER_PACKAGE(state, payload) {
        state.userPackage = payload
    },
    SET_USER_TEST(state, payload) {
        state.userTest = payload
    },
    SET_USER_BE(state, payload) {
        state.userBe = payload
    },
    SET_USER_GIFTS(state, payload) {
        state.userGifts = payload
    },
    SET_USER_CELL(state, payload) {
        state.userCell = payload
    },
    SET_USER_LEED(state, payload) {
        state.userLeed = payload
    },
    SET_USER_BIZ(state, payload) {
        state.userBiz = payload
    },
    SET_USER_BIZ_TEST(state, payload) {
        state.userBizTest = payload
    },
    SET_PROSPECT(state, payload) {
        state.prospect = payload
    },
    UPDATE_USER(state, user) {
        state.users.forEach(u => {
            if(u.id === user.id) {
                u = user
            }
        })
    },
    UPDATE_PROSPECT(state, user) {
        state.users.forEach(u => {
            if(u.id === user.id) {
                u = user
            }
        })
    },
    DELETE_USER(state, user) {
        state.users.map(u => {
            if (u.id === user.id) {
                for (let key in user) {
                    u[key] = user[key]
                }
            }
        })
    },
    RESTORE_USER(state, user) {
        state.users.map(u => {
            if (u.id === user.id) {
                for (let key in user) {
                    u[key] = user[key]
                }
            }
        })
    },
}
