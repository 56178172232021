import {http, httpFile} from "./http_service"

export function showProspectPage(id) {
    return http().get(`/profile/prospect-page/${id}`)
}

export function showUserPage(user, inst) {
    return http().get(`/user-pay/${user}/${inst}`)
}

export function showUserPageV(user) {
    return http().get(`/vizitka/${user}`)
}

export function showUserLeedbot(user, inst) {
    return http().get(`/leedbot/${user}/${inst}`)
}

export function ifUserTest(user) {
    return http().get(`/if-user-test/${user}`)
}

export function showUserTest(user) {
    return http().get(`/health-test/${user}`)
}

export function updateProfile(id, data) {
    return http().post(`/profile/update/${id}`, data)
}

export function updateProfileAvatar(id, data) {
    return httpFile().post(`/profile/update/${id}/avatar`, data)
}

export function updateProfileAuto(id, data) {
    return httpFile().post(`/profile/update/${id}/photo_auto`, data)
}

export function deleteProfileAuto(id) {
    return httpFile().post(`/profile/delete/${id}/photo_auto`)
}

export function updateProfileMoney(id, data) {
    return httpFile().post(`/profile/update/${id}/photo_money`, data)
}

export function deleteProfileMoney(id) {
    return httpFile().post(`/profile/delete/${id}/photo_money`)
}

export function updateVizImgOne(id, data) {
    return httpFile().post(`/profile/update/${id}/viz_img_one`, data)
}

export function deleteVizImgOne(id) {
    return httpFile().post(`/profile/delete/${id}/viz_img_one`)
}

export function updateVizImgTwo(id, data) {
    return httpFile().post(`/profile/update/${id}/viz_img_two`, data)
}

export function deleteVizImgTwo(id) {
    return httpFile().post(`/profile/delete/${id}/viz_img_two`)
}

export function updateVizImgThree(id, data) {
    return httpFile().post(`/profile/update/${id}/viz_img_three`, data)
}

export function deleteVizImgThree(id) {
    return httpFile().post(`/profile/delete/${id}/viz_img_three`)
}

export function updateVizImgFour(id, data) {
    return httpFile().post(`/profile/update/${id}/viz_img_four`, data)
}

export function deleteVizImgFour(id) {
    return httpFile().post(`/profile/delete/${id}/viz_img_four`)
}

export function updateVizImgFive(id, data) {
    return httpFile().post(`/profile/update/${id}/viz_img_five`, data)
}

export function deleteVizImgFive(id) {
    return httpFile().post(`/profile/delete/${id}/viz_img_five`)
}

export function updateProspect(id, data) {
    return http().post(`/profile/update/prospect/${id}`, data)
}

export function deleteUserProspect(id) {
    return http().delete(`/profile/prospect-delete/${id}`)
}

export function restoreUserProspect(id) {
    return http().post(`/profile/prospect-restore/${id}`)
}

export function changeProfilePassword(id, data) {
    return http().post(`/profile/update/${id}/password`, data)
}

export function loadProspects() {
    return http().get('/profile/prospects')
}

export function loadProspectsBasket() {
    return http().get('/profile/prospects-basket')
}

export function loadProspectsToday() {
    return http().get('/profile/prospects/today')
}

export function loadProfilePackages() {
    return http().get('/profile/packages')
}

export function loadProfilePackage() {
    return http().get('/profile/package')
}

export function loadProfileAll() {
    return http().get('/profile/package/all')
}

export function updateDemoAll() {
    return http().post('/profile/package-demo/open')
}

export function loadProfileTest() {
    return http().get('/profile/package/test')
}

export function loadProfileBe() {
    return http().get('/profile/package/be')
}

export function loadProfileZg() {
    return http().get('/profile/package/zg')
}

export function loadProfileAroma() {
  return http().get('/profile/package/aroma')
}

export function loadProfileAloe() {
  return http().get('/profile/package/aloe')
}

export function loadProfileAvBox() {
  return http().get('/profile/package/av-box')
}

export function loadProfileGifts() {
    return http().get('/profile/package/gifts')
}

export function loadProfileCell() {
    return http().get('/profile/package/cell')
}

export function loadProfileLeed() {
    return http().get('/profile/package/leed')
}

export function loadProfileBiz() {
    return http().get('/profile/package/biz')
}

export function loadProfileBizTest() {
    return http().get('/profile/package/biz-test')
}

export function loadProspectsCount() {
    return http().get('/profile/prospects/count')
}